.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: loader 3s forwards;
    user-select: none;
    visibility: hidden;
}

.logo-loader {
    height: 4.166666666666666VW;
    max-width: 100%;
    animation: pulse 4s forwards;
}

@keyframes loader {
    0% {
        opacity: 1;
        display: block;
        z-index: 9999;
        visibility: unset;
    }
    25% {
        opacity: 1;
        display: block;
        z-index: 9999;
        visibility: unset;
    }
    50% {
        opacity: 1;
        display: block;
        z-index: 9999;
        visibility: unset;
    }
    75% {
        opacity: 0;
        z-index: 9999;
    }
    100% {
        opacity: 0;
        display: none;
        z-index: unset;
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    25% {
        opacity: 1;
        transform: scale(1.03);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    75% {
        opacity: 1;
        transform: scale(1.03);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .logo-loader {
        height: 40px;
    }
}