.section-6 {
    padding: 9.722222222222223VW 0 0 0;
    background: transparent linear-gradient(180deg, #352C29 0%, #715538 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 24.305555555555554VW;
}

.title-section-6 {
    font-size: 3.75VW;
    color: #F8C01B;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.09375000000000001VW;
    line-height: 4.722222222222222VW;
}

.text-section-6 {
    font-size: 1.3888888888888888VW;
    color: #B5B1A7;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    text-align: center;
    margin: 3.3333333333333335VW 0;
}

.wrapper-link-section-6 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link-section-6 {
    font-size: 1.3888888888888888VW;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.7361111111111112VW;
    text-align: center;
    background-color: #2166D5BF;
    padding: 1.1111111111111112VW 2.013888888888889VW;
    border-radius: 69.44444444444444VW;
    text-decoration: none;
    white-space: nowrap;
}

.link-section-6:hover {
    color: #fff;
    background-color: #2166d5;
    text-decoration: none;
}

.wrapper-image-section-6 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.image-section-6 {
    height: auto;
    max-width: 100%;
    margin-top: 5.763888888888889VW;
    margin-bottom: -24.305555555555554VW;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-6 {
        padding: 60px 0 0 0;
        margin-bottom: 110px;
    }
    .title-section-6 {
        font-size: 30px;
        text-align: center;
        letter-spacing: -1px;
        line-height: 36px;
    }
    .text-section-6 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: center;
        margin: 24px 0 16px 0;
    }
    .wrapper-link-section-6 {
        /*  */
    }
    .link-section-6 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: center;
        padding: 16px 29px;
    }
    .wrapper-image-section-6 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .image-section-6 {
        max-height: 100%;
        margin-top: 60px;
        margin-bottom: -120px;
    }
}