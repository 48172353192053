.section-8 {
    background-color: #F8C01B;
    background-image: url('./assets/bg.svg');
    background-position: right top;
    background-size: auto 45VW;
    background-repeat: no-repeat;
    padding: 6.666666666666667VW 0;
}

.title-1-section-8 {
    font-size: 5VW;
    color: #896700;
    font-weight: 400;
    letter-spacing: -0.125VW;
    line-height: 6.25VW;
    text-align: left;
}

.span-title-1-section-8 {
    font-weight: 600;
}

.title-2-section-8 {
    font-size: 2.2222222222222223VW;
    color: #896700;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.8055555555555554VW;
    text-align: left;
    opacity: 0.5;
    margin: 1.1111111111111112VW 0 3.3333333333333335VW 0;
}

.text-1-section-8 {
    font-size: 1.3888888888888888VW;
    color: #896700;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.8055555555555554VW;
    text-align: left;
}

.text-2-section-8 {
    font-size: 1.3888888888888888VW;
    color: #896700;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.8055555555555554VW;
    text-align: left;
    margin: 2.2222222222222223VW 0 5VW 0;
}

.image-section-8 {
    height: 3.75VW;
    max-width: 100%;
    margin-bottom: 0.8333333333333334VW;
    display: block;
}

.link-section-8 {
    font-size: 1.3888888888888888VW;
    color: #2166D5BF;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.8055555555555554VW;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
}

.link-section-8:hover {
    color: #2166D5BF;
    text-decoration: underline;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-8 {
        background-size: auto 200px;
        padding: 60px 0;
    }
    .title-1-section-8 {
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 36px;
        text-align: left;
    }
    .span-title-1-section-8 {
        /*  */
    }
    .title-2-section-8 {
        font-size: 30px;
        letter-spacing: 0px;
        line-height: 36px;
        text-align: left;
        opacity: 0.5;
        margin: 16px 0 24px 0;
    }
    .text-1-section-8 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: left;
    }
    .text-2-section-8 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: left;
        margin: 24px 0 60px 0;
    }
    .image-section-8 {
        height: 50px;
        margin-bottom: 16px;
    }
    .link-section-8 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: left;
    }
    .link-section-8:hover {
        /*  */
    }
}