.section-10 {
    background-image: url('./assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 5VW 0;
}

.card-1-section-10 {
    background: #F7F5EF;
    box-shadow: 0px 0.20833333333333334VW 0.4166666666666667VW #00000029;
    border-radius: 1.3888888888888888VW;
    padding: 3.3333333333333335VW 0 6.805555555555555VW 0;
}


/*  */

.title-1-section-10 {
    font-size: 2.5VW;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.0625VW;
    line-height: 3.125VW;
    color: #3C3A35;
}

.text-1-section-10 {
    font-size: 1.3888888888888888VW;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
    line-height: 1.7361111111111112VW;
    color: #6C6A64;
    margin: 1.1111111111111112VW 0 3.3333333333333335VW 0;
}


/*  */

.title-2-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
}

.text-2-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    margin-bottom: 1.1111111111111112VW;
    margin-top: 0.8333333333333334VW;
}

.link-2-section-10 {
    font-size: 1.3888888888888888VW;
    color: #2166D5BF;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.link-2-section-10:hover {
    color: #2166D5BF;
    text-decoration: underline;
}


/*  */

.title-3-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    margin-bottom: 1.1111111111111112VW;
    margin-top: 3.3333333333333335VW;
}

.text-3-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    margin-bottom: 3.3333333333333335VW;
}


/*  */

.title-4-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    margin-bottom: 0.8333333333333334VW;
}

.text-4-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
}


/*  */

.card-2-section-10 {
    background-color: #fff;
    border: 0.20833333333333334VW dashed #F8C01B;
    border-radius: 1.3888888888888888VW;
    padding: 1.6666666666666667VW 3VW 2.2222222222222223VW 3VW;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-5-section-10 {
    font-size: 1.3888888888888888VW;
    color: #3C3A35;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    text-align: center;
}

.image-section-10 {
    height: auto;
    max-width: 100%;
    margin: 1.1111111111111112VW 1.6666666666666667VW;
}

.text-5-section-10 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
    line-height: 1.9444444444444444VW;
    text-align: center;
    margin-bottom: 0.8333333333333334VW;
}

.link-5-section-10 {
    font-size: 1.3888888888888888VW;
    color: #2166D5BF;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.link-5-section-10:hover {
    color: #2166D5BF;
    text-decoration: underline;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-10 {
        padding: 60px 0 60px 0;
    }
    .card-1-section-10 {
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 20px;
        padding: 40px 15px 60px 15px;
    }
    .title-1-section-10 {
        font-size: 30px;
        text-align: center;
        letter-spacing: -1px;
        line-height: 36px;
    }
    .text-1-section-10 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
        color: #6C6A64;
        margin: 16px 0 24px 0;
    }
    .title-2-section-10 {
        font-size: 24px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 30px;
    }
    .text-2-section-10 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
        margin-bottom: 16px;
        margin-top: 12px;
    }
    .wrapper-link-2-section-2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .link-2-section-10 {
        font-size: 16px;
    }
    .link-2-section-10:hover {
        /*  */
    }
    .title-3-section-10 {
        font-size: 24px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 16px;
        margin-top: 24px;
    }
    .text-3-section-10 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .title-4-section-10 {
        font-size: 24px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .text-4-section-10 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
    }
    .card-2-section-10 {
        margin-top: 40px;
        width: 100%;
        float: unset;
        border: 3px dashed #F8C01B;
        border-radius: 20px;
        padding: 24px 15px 24px 15px;
    }
    .title-5-section-10 {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        text-align: center;
    }
    .image-section-10 {
        max-height: 200px;
        max-width: 100%;
        margin: 16px 24px;
    }
    .text-5-section-10 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: center;
        margin-bottom: 12px;
    }
    .link-5-section-10 {
        font-size: 16px;
        text-align: center;
    }
    .link-5-section-10:hover {
        /*  */
    }
}