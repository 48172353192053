.section-1 {
    padding: 6.388888888888888VW 0 0 0;
    background: transparent linear-gradient(120deg, #3C3A35 0%, #918F86 100%) 0% 0% no-repeat padding-box;
}

.title-section-1 {
    font-size: 5VW;
    color: #EFEADF;
    font-weight: 600;
    letter-spacing: -0.15VW;
    line-height: 6.25VW;
    margin-top: -6.388888888888888VW;
}

.span-title-section-1 {
    color: #F8C01B;
}

.wrapper-image-section-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.image-section-1 {
    height: auto;
    max-width: 100%;
}

.row-section-1 {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-1 {
        padding: 48px 0 0 0;
    }
    .title-section-1 {
        font-size: 30px;
        letter-spacing: 0;
        line-height: 36px;
        margin-top: unset;
        margin-bottom: 30px;
        text-align: center;
    }
    .span-title-section-1 {
        /*  */
    }
    .image-section-1 {
        max-height: 100%;
    }
    .row-section-1 {
        /* */
    }
}