.section-2 {
    padding: 10.48611111111111VW 0 0 0;
    height: 73.125VW;
    background-size: auto 73.125VW;
    background-repeat: no-repeat;
    background-position: left top;
}

.title-1-section-2 {
    font-size: 1.6666666666666667VW;
    color: #6C6A64;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.083333333333333VW;
    margin-bottom: 1.6666666666666667VW;
}

.title-2-section-2 {
    font-size: 5VW;
    color: #3C3A35;
    font-weight: 600;
    letter-spacing: -0.15VW;
    line-height: 5.694444444444445VW;
    text-transform: uppercase;
}

.span-title-2-section-2 {
    color: #F8C01B;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-2 {
        padding: calc(115vw) 0 0 0;
        height: fit-content;
        background-size: calc(100vw - 15px) auto;
        background-position: left top;
    }
    .title-1-section-2 {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 24px;
        margin-top: 48px;
        text-align: left;
    }
    .title-2-section-2 {
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 36px;
        text-align: left;
    }
    .span-title-2-section-2 {
        /*  */
    }
}