.section-5 {
    padding: 27.77777777777778VW 0 6.944444444444445VW 0;
}

.card-section-5 {
    background: #F8F8F8;
    border-radius: 1.3888888888888888VW;
    padding: 6.805555555555555VW 0;
}

.wrapper-image-section-5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.image-section-5 {
    height: auto;
    max-width: 100%;
    margin-top: -27.77777777777778VW;
}

.title-section-5 {
    font-size: 3.75VW;
    color: #3C3A35;
    font-weight: 600;
    text-align: center;
    letter-spacing: -0.09375000000000001VW;
    line-height: 4.722222222222222VW;
}

.text-section-5 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    margin: 3.3333333333333335VW auto 1.6666666666666667VW auto;
}

.bold-text-section-5 {
    font-weight: 600;
}

.wrapper-link-section-5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link-section-5 {
    font-size: 1.3888888888888888VW;
    color: #2166D5BF;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

.link-section-5:hover {
    color: #2166D5BF;
    text-decoration: underline;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-5 {
        padding: 160px 0 60px 0;
    }
    .card-section-5 {
        border-radius: 20px;
        /* padding fix */
        padding: 60px 15px;
    }
    .wrapper-image-section-5 {
        /*  */
    }
    .image-section-5 {
        max-height: 100%;
        margin-top: -160px;
        margin-bottom: 10px;
    }
    .title-section-5 {
        font-size: 30px;
        text-align: center;
        letter-spacing: -1px;
        line-height: 36px;
    }
    .text-section-5 {
        font-size: 16px;
        text-align: center;
        letter-spacing: 0px;
        line-height: 24px;
        margin: 24px auto 16px auto;
    }
    .bold-text-section-5 {
        /*  */
    }
    .wrapper-link-section-5 {
        /*  */
    }
    .link-section-5 {
        font-size: 16px;
        text-align: center;
    }
    .link-section-5:hover {
        /*  */
    }
}