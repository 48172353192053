/* .nav {
    height: 54px;
    width: 100%;
    background-color: rgba(247, 247, 247, 0.7);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    z-index: 1021;
}

.wrapper-nav {
    height: 54px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo-nav {
    height: 32px;
    max-width: 100%;
}

.wrapper-lang-nav {}

.image-lang-nav {
    height: 24px;
    max-width: 100%;
    margin-left: 16px;
    cursor:pointer;
}

.wrapper-links-nav {
    height: 38px;
    padding: 0 5px;
    border-radius: 100px;
    background: #e6e6e6d5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.link-nav {
    font-size: 16px;
    font-weight: 600;
    color: #606C77;
    letter-spacing: 0;
    line-height: 20px;
    text-decoration: none;
    padding: 5px 18px;
    border-radius: 100px;
}

.link-nav:hover {
    color: #606C77;
    text-decoration: none;
} 

.wrapper-menu-nav {
    display: none;
}

.image-menu-nav {
    display: none;
}
*/

.nav {
    height: 3.75VW;
    width: 100%;
    background-color: rgba(247, 247, 247, 0.7);
    backdrop-filter: blur(0.6944444444444444VW);
    position: fixed;
    top: 0;
    z-index: 1021;
}

.wrapper-nav {
    height: 3.75VW;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo-nav {
    height: 2.2222222222222223VW;
    max-width: 100%;
    display: block;
}

.wrapper-lang-nav {}

.image-lang-nav {
    height: 1.6666666666666667VW;
    max-width: 100%;
    margin-left: 1.1111111111111112VW;
    cursor: pointer;
}

.image-lang-nav--active {
    border: 3px solid #2166D5BF;
    border-radius: 100px;
    height: 2.1VW;
    margin-bottom: -2px;
}

.wrapper-links-nav {
    height: 2.638888888888889VW;
    padding: 0 0.3472222222222222VW;
    border-radius: 6.944444444444445VW;
    background: #e6e6e6d5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.link-nav {
    font-size: 1.1111111111111112VW;
    font-weight: 600;
    color: #606C77;
    letter-spacing: 0;
    line-height: 1.3888888888888888VW;
    text-decoration: none;
    padding: 0.3472222222222222VW 1.25VW;
    border-radius: 6.944444444444445VW;
}

.link-nav:hover {
    color: #606C77;
    text-decoration: none;
}

.wrapper-menu-nav {
    display: none;
}

.image-menu-nav {
    height: 30px;
    max-width: 100%;
    display: block;
    cursor: pointer;
}


/*  */

.menu-nav {
    display: none;
}

.link-nav--active {
    background-color: #fff;
    box-shadow: 0px 0px 3px #00000029;
    user-select: none;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .nav {
        height: 54px;
        background-color: rgba(247, 247, 247, 0.7);
        backdrop-filter: blur(10px);
    }
    .wrapper-nav {
        height: 54px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .logo-nav {
        height: 30px;
    }
    .wrapper-lang-nav {
        display: none;
    }
    .wrapper-links-nav {
        display: none;
    }
    .wrapper-menu-nav {
        display: block;
    }
    .menu-nav {
        display: block;
        background-color: rgba(247, 247, 247, 0.7);
        backdrop-filter: blur(10px);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        z-index: 1021;
        transform: translate(0, -100%);
        transition: all .6s ease;
    }
    .menu-nav--active {
        transform: translate(0, 0) !important;
    }
    .icon-menu-nav {
        height: 25px;
        max-width: 100%;
        display: block;
        cursor: pointer;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    .wrapper-links-menu-nav {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .link-menu-nav {
        font-size: 24px;
        font-weight: 600;
        color: #606C77;
        letter-spacing: 0;
        line-height: 30px;
        text-decoration: none;
        padding: 5px 18px;
        border-radius: 100px;
        /*  */
        margin: 12px 0;
    }
    .link-menu-nav:hover {
        color: #606C77;
        text-decoration: none;
    }
    .wrapper-image-lang-menu-nav {
        width: 100%;
        position: absolute;
        top: 95%;
        left: 50%;
        transform: translate(-50%, -95%);
        /*  */
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .image-lang-menu-nav {
        height: 30px;
        max-width: 100%;
        margin: 0 7.5px;
        cursor: pointer;
    }
    .image-lang-menu-nav--active {
        height: 33px;
        border: 3px solid #2166D5BF;
        border-radius: 100px;
    }
    .link-menu-nav--active {
        background-color: #fff;
        box-shadow: 0px 0px 3px #00000029;
        user-select: none;
    }
}