.section-4 {
    background-color: #F8C01B;
    padding: 6.666666666666667VW 0;
}

.title-section-4 {
    font-size: 5VW;
    color: #896700;
    font-weight: 600;
    letter-spacing: -0.125VW;
    line-height: 5.694444444444445VW;
    margin-bottom: 3.3333333333333335VW;
}

.span-title-section-4 {
    color: #fff;
}

.text-section-4 {
    font-size: 1.3888888888888888VW;
    color: #896700;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
}

.span-text-section-4 {
    font-weight: 600;
}

.wrapper-image-section-4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.image-section-4 {
    max-height: 67.15277777777777VW;
    max-width: 100%;
    margin-bottom: -14.86111111111111VW;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-4 {
        padding: 120px 0 60px 0;
    }
    .title-section-4 {
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 36px;
        margin-bottom: 24px;
    }
    .span-title-section-4 {
        /*  */
    }
    .text-section-4 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
    }
    .span-text-section-4 {
        /*  */
    }
    .wrapper-image-section-4 {
        justify-content: flex-end;
    }
    .image-section-4 {
        max-height: unset;
        max-width: 95%;
        margin-bottom: -120px;
        margin-top: 30px;
    }
}