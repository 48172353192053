.slide-section-9 {
    position: relative;
}

.image-section-9 {
    height: 51.66666666666667VW;
    width: 100%;
    object-fit: cover;
    margin-bottom: -0.2777777777777778VW;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .image-section-9 {
        height: auto;
        max-width: 100%;
        object-fit: unset;
        margin-bottom: -15px;
    }
    .slide-section-9 {
        /*  */
    }
}