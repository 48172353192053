/* .footer {
    background-color: #88B337;
    padding: 24px 0;
}

.text-footer {
    font-size: 20px;
    color: #DFFFA3;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 32px;
    text-align: center;
}

.copyright {
    background-color: #6C6A64;
    padding: 17px 0;
}

.text-copyright {
    font-size: 20px;
    color: #B5B1A7;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 32px;
}

.col-left-copyright {
    text-align: left;
}

.col-right-copyright {
    text-align: right;
} */

.footer {
    background-color: #88B337;
    padding: 1.6666666666666667VW 0;
}

.text-footer {
    font-size: 1.3888888888888888VW;
    color: #DFFFA3;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
    text-align: center;
}

.copyright {
    background-color: #6C6A64;
    padding: 1.1805555555555556VW 0;
}

.text-copyright {
    font-size: 1.1111111111111112VW;
    color: #B5B1A7;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.6666666666666667VW;
}

.col-left-copyright {
    text-align: left;
}

.col-right-copyright {
    text-align: right;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .footer {
        padding: 18px 0;
    }
    .text-footer {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
    }
    .copyright {
        padding: 16px 0;
    }
    .text-copyright {
        font-size: 14px;
        letter-spacing: 0px;
        line-height: 20px;
    }
    .col-left-copyright {
        text-align: center;
        margin-bottom: 5px;
    }
    .col-right-copyright {
        text-align: center;
    }
}