.section-3 {
    background-color: #fff;
}

.text-section-3 {
    font-size: 1.3888888888888888VW;
    color: #6C6A64;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 2.2222222222222223VW;
}

.span-text-section-3 {
    font-weight: 600;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-3 {
        background-color: #fff;
    }
    .text-section-3 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        margin-top: 30px;
    }
    .span-text-section-3 {
        /*  */
    }
}