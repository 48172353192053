@font-face {
    font-family: 'Quicksand';
    src: url('../../fonts/Quicksand/Quicksand-Bold.ttf');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../../fonts/Quicksand/Quicksand-SemiBold.ttf');
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../../fonts/Quicksand/Quicksand-Medium.ttf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../../fonts/Quicksand/Quicksand-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Quicksand';
    src: url('../../fonts/Quicksand/Quicksand-Light.ttf');
    font-style: normal;
    font-weight: 300;
}

body {
    background-color: #fff;
}

html {
    scroll-behavior: smooth;
}

* {
    font-family: 'Quicksand', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}

.slick-slide div {
    outline: none !important;
    cursor: grab;
}

.visible-lg {
    display: block;
}

.visible-sm {
    display: none;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .visible-lg {
        display: none;
    }
    .visible-sm {
        display: block;
    }
}


/* .slick-slide.slick-active {
    z-index: 2;
} */