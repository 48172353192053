.section-7 {
    padding: 0 0 6.805555555555555VW 0;
}


/*  */

.card-section-7 {
    padding: 3.3333333333333335VW 0 6.805555555555555VW 0;
    background-color: #F8F8F8;
    border-radius: 1.3888888888888888VW;
}

.title-section-7 {
    font-size: 2.5VW;
    color: #6C6A64;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 3.125VW;
    text-align: center;
    /*  */
    margin-bottom: 6.805555555555555VW;
}


/*  */

.title-item-section-7 {
    font-size: 1.6666666666666667VW;
    color: #B5B1A7;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 2.083333333333333VW;
    text-align: center;
    /*  */
    margin-bottom: 3.3333333333333335VW;
}


/*  */

.wrapper-item-section-7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-item-section-7 {
    max-height: 5.555555555555555VW;
    max-width: 12.5VW;
}

.text-item-section-7 {
    font-size: 1.3888888888888888VW;
    color: #3C3A35;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.7361111111111112VW;
    text-align: center;
    margin-top: 1.6666666666666667VW;
}


/*  */

.group-section-7 {
    margin-bottom: 6.805555555555555VW;
}


/*  */

.table-section-7 {
    width: 100%;
    border-collapse: collapse;
}

.table-section-7 tr th {
    font-size: 1.3888888888888888VW;
    color: #3C3A35;
    font-weight: 600;
    line-height: 1.7361111111111112VW;
    letter-spacing: 0px;
    text-align: center;
    /*  */
    border-bottom: 0.1388888888888889VW solid #B5B1A7;
    padding: 0.6944444444444444VW 0;
}

.table-section-7 tr td {
    font-size: 1.3888888888888888VW;
    color: #3C3A35;
    font-weight: 400;
    line-height: 1.7361111111111112VW;
    letter-spacing: 0px;
    text-align: center;
    /*  */
    padding: 0.6944444444444444VW 0;
    border-bottom: 0.06944444444444445VW solid #B5B1A7;
}

.table-section-7>tr:last-child>td {
    border-bottom: unset;
}


/*  */

.dimension-image-section-7 {
    max-height: 25.833333333333336VW;
    max-width: 100%;
}


/*  */

.wrapper-link-section-7 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.link-section-7 {
    font-size: 1.3888888888888888VW;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 1.7361111111111112VW;
    text-align: center;
    background-color: #2166D5BF;
    padding: 1.1111111111111112VW 2.013888888888889VW;
    border-radius: 69.44444444444444VW;
    text-decoration: none;
    white-space: nowrap;
}

.link-section-7:hover {
    color: #fff;
    background-color: #2166d5;
    text-decoration: none;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
    .section-7 {
        padding: 30px 0 60px 0;
    }
    .card-section-7 {
        padding: 40px 15px 60px 15px;
        border-radius: 20px;
    }
    .title-section-7 {
        font-size: 30px;
        letter-spacing: 0px;
        line-height: 36px;
        margin-bottom: 48px;
    }
    .title-item-section-7 {
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 30px;
        margin-bottom: 24px;
    }
    .wrapper-item-section-7 {
        margin: 30px 0 0 0;
    }
    .image-item-section-7 {
        max-height: 70px;
        max-width: 200px;
    }
    .text-item-section-7 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        margin-top: 16px;
    }
    .group-section-7 {
        margin-bottom: 48px;
    }
    .table-section-7 {
        /*  */
    }
    .table-section-7 tr th {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        border-bottom: 2px solid #B5B1A7;
        padding: 10px 0;
    }
    .table-section-7 tr td {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        padding: 10px 0;
        border-bottom: 1px solid #B5B1A7;
    }
    .dimension-image-section-7 {
        max-height: 300px;
        max-width: 100%;
    }
    .wrapper-link-section-7 {
        /*  */
    }
    .link-section-7 {
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 24px;
        text-align: center;
        padding: 16px 29px;
    }
}